import Header from './../components/header/Header'

const Home = () => {
    return (
		<>
			<Header />
<main className="section">
	<div className="container">
		<div className="content-list">
			<div className="content-list__item">
				<h2 className="title-2">Frontend</h2>
				<div className="content-list__technologies">
					<div className="content-list__technology">
						JavaScript/jQuery
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						TypeScript
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						React
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						Next.js
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						Redux
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						Vue.js
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						HTML
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						CSS
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						SCSS
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						BootStrap
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						MaterialUI
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						TailwindCSS
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div className="content-list__item">
				<h2 className="title-2">Backend</h2>
				<div className="content-list__technologies">
					<div className="content-list__technology">
						PHP
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						Laravel
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						NodeJS
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						PostgreSQL
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<div className="content-list__item">
				<h2 className="title-2">CMS</h2>
				<div className="content-list__technologies">
					<div className="content-list__technology">
						WordPress
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						Webflow
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						OpenCart
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						Shopify
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
					<div className="content-list__technology">
						Hubspot
						<div className="content-list__level">
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#00B026"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
							<svg width="15" height="15" viewBox="0 0 20 20" fill="none"
								 xmlns="http://www.w3.org/2000/svg">
								<rect width="20" height="20" rx="10" fill="#D7D7D7"/>
							</svg>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</main>
		</>
	);
}

export default Home;